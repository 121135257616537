<template>
  <div>
    <div id="back">
      <el-link type="success">{{ name }}{{ title }}</el-link>
      <el-button @click="goBack()">返回上一级</el-button>
    </div>
    <div class="head-title-box_abnormal">
      <el-tabs tab-position="left" style="width:1200px;height: 1900px;">
        <el-tab-pane label="实时监测数据">
          <!--空气温度-->
          <airTemp :temperList="temperList"></airTemp>
          <div style="display:flex;">
            <!--土壤温度-->
            <airHumidity :landTemperList="landTemperList"></airHumidity>
            <!--光照强度-->
            <soilHumidity :lightList="lightList" style="margin-left: 10px"></soilHumidity>
          </div>
          <div style="display:flex;">
            <!--空气湿度-->
            <lightHumidity :humidList="humidList"></lightHumidity>
            <!--二氧化碳浓度-->
            <soilTemp :co2List="co2List" style="margin-left: 10px"></soilTemp>
          </div>
          <div style="display:flex;">
            <!--水肥PH值-->
            <waterPH :landPHList="landPHList"></waterPH>
            <!--土壤湿度-->
            <co2Humidity :landHumidList="landHumidList" style="margin-left: 10px"></co2Humidity>
          </div>
        </el-tab-pane>
        <el-tab-pane label="预警报警记录">
          <el-table
              :data="waringList"
              border
              style="width: 100%"
              :row-class-name="tableRowClassName">

            <el-table-column
                prop="baseName"
                label="基地归名称"
            >
            </el-table-column>
            <el-table-column
                prop="greenhouseName"
                label="大棚名称">
            </el-table-column>
            <el-table-column
                prop="remark"
                label="触发原因">
            </el-table-column>
            <el-table-column
                prop="value"
                label="触发值">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="报警时间"
            >
            </el-table-column>
            <el-table-column
                prop="date"
                label="操作">
              <!--              <template slot-scope="scope">-->
              <!--                <el-button-->
              <!--                        size="mini"-->
              <!--                        @click="handleEdit(scope.$index, scope.row)">详情-->
              <!--                </el-button>-->
              <!--              </template>-->
            </el-table-column>


          </el-table>
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="数据历史记录">历史监测数据</el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import airTemp from "@/components/echarts/airTemp";
import airHumidity from "@/components/echarts/airHumidity";
import soilHumidity from "@/components/echarts/soilHumidity";
import soilTemp from "@/components/echarts/soilTemp";
import lightHumidity from "@/components/echarts/lightHumidity";
import waterPH from "@/components/echarts/waterPH";
import co2Humidity from "@/components/echarts/co2Humidity";
import moment from "moment";

export default {
  name: "CanopyManagement",
  data() {
    return {
      name: "",
      title: "",
      bid: "",
      id: "",
      tableData: [],
      dialogVisible: false,
      value1: '',
      waringList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      changeList: [],
      temperList: [],//空气温度
      landTemperList: [],//土壤温度
      lightList: [],//光照强度
      humidList: [],//空气湿度
      co2List: [],//二氧化碳浓度
      landPHList: [],//水肥PH值
      landHumidList: [],//土壤湿度
      date:new Date(),//当天时间
    }
  },

  components: {
    airTemp,
    airHumidity,
    soilHumidity,
    lightHumidity,
    soilTemp,
    waterPH,
    co2Humidity
  },
  created() {
    this.title = this.$route.query.title
    this.name = this.$route.query.name
    this.bid = this.$route.query.bid
    this.id = this.$route.query.id//大棚id
    // this.getData()
  },
  mounted() {
    this.date=moment(this.date).format('YYYY-MM-DD');
    this.date='2022-02-11'
    this.getData()

    this.getDataList()
    this.$bas.$emit("suiji",'/qiheIndex')

  },

  methods: {
     getData() {

      this.$api.post('/gHouse/getEnvironmentalData', {bid: this.bid,gid: this.id,date:this.date}, result => {
      // this.$api.post('data/getTodayList', {bid: this.id}, result => {
        this.temperList = result.airTemperature
        this.landTemperList = result.soilTemperature
        this.lightList = result.lightIntensity
        this.humidList = result.airHumidity
        this.co2List = result.co2Concentration
        this.landPHList = result.PHvalue
        this.landHumidList = result.soilMoisture
      })
    },
    //返回上一级
    goBack() {
      // this.$router.go(-1,);
      this.$router.push({
        name: "grennhouse",
        query: {
          bid: this.bid,
          names: this.name
        }
      })
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    getDataList() {
      this.$api.get('error/getTbErrorGid', {gid: this.id, page: this.pageIndex, size: this.pageSize}, result => {
        this.waringList = result.data.records
        this.totalPage = result.data.total
      })
    },

  }
}
</script>

<style scoped lang="scss">
#back {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
}

#back .el-button {
  width: 100px;
  height: 40px;
  background: #009201;
  border-radius: 20px;
  color: #FFFFFF;
  text-align: center;
  //margin-top: 10px;
}

.head-title-box {
  height: 300px;
  background: #FFFFFF;
  margin-top: 20px;
  text-align: center;
  //margin-bottom: 30px;
}

.head-title-box_abnormal {
  width: 1200px;
  height: 1900px;
  background: #FFFFFF;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.head-title-con-name {
  margin-top: 9px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.head-title-con-type {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}


.head-title-con-left {
  height: 76px;
  margin-top: 10px;
}

.head-title-con {
  height: 76px;
  line-height: 76px;
  cursor: pointer;
}

.head-title-con-box {
  height: 200px;
  cursor: pointer;
  padding: 0 5px;
}

.head-title-con-box_actice {
  height: 200px;
  background: #CBE9CB;
  cursor: pointer;
  padding: 0 5px;
}

/*.head-title-con-img {*/
/*  height: 80px;*/
/*}*/


.head-title-con-type2 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 25px;

}

.head-title-con-data {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0090FF;
  margin-top: 6px;
}


</style>
